import JavaScript from "../images/javascript.svg"
import React from "../images/react.svg"
import Nodejs from "../images/nodejs.svg"
import Git from "../images/git.svg"
import AWS from "../images/aws.svg"
import MongoDB from "../images/mongodb.svg"
import Figma from "../images/figma.svg"
import Jest from "../images/jest.svg"
import Postman from "../images/postman.svg"
import PostgreSQL from "../images/postgresql.svg"
import Trello from "../images/trello.svg"

const Skills = () => {

    return (
        <div>
            <h5 style={{marginBottom: 7}}className=" uk-text-bold">
            Skills
            </h5>
            <img uk-tooltip="title: JavaScript; pos:top" className="skills-logo" src={JavaScript} alt="JavaScript logo" />
            <img uk-tooltip="title: React; pos:top" className="skills-logo" src={React} alt="React logo" />

            <img uk-tooltip="title: Node.js; pos:top" className="skills-logo" src={Nodejs} alt="Node.js logo" />
            <img uk-tooltip="title: PostgreSQL; pos:top" className="skills-logo" src={PostgreSQL} alt="PostgreSQL logo" />
            <img uk-tooltip="title: MongoDB; pos:top" className="skills-logo" src={MongoDB} alt="MongoDB logo"/>

            <img uk-tooltip="title: Git CLI; pos:top" className="skills-logo" src={Git} alt="Git logo" />
            <img uk-tooltip="title: Jest; pos:top" className="skills-logo" src={Jest} alt="Jest logo" />
            <img uk-tooltip="title: Postman; pos:top" className="skills-logo" src={Postman} alt="Postman logo" />
            <img uk-tooltip="title: AWS; pos:top" className="skills-logo" src={AWS} alt="Amazon Web Services logo" />

            <img uk-tooltip="title: Figma; pos:top" className="skills-logo" src={Figma} alt="Figma logo" />
            <img uk-tooltip="title: Trello; pos:top" className="skills-logo" src={Trello} alt="Trello logo" />
        </div>
    )
};

export default Skills;