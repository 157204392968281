const Copyright = () => {
    
    return (
        <div style={{marginTop: "1rem"}}className="uk-flex uk-flex-center uk-flex-middle uk-flex-column">
        <p style={{margin: "0 0 0.25rem 0"}} className="uk-text-meta">© 2024 Monica Williams</p>
        <p style={{margin: "0 0 0.25rem 0"}} className="uk-text-meta">Made with ❤️</p>
        </div>
    )
}

export default Copyright;