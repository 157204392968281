import React, { useState } from 'react';
import PPSS1 from "../images/pp-ss1.png"
import PPSS2 from "../images/pp-ss2.png"
import PPSS3 from "../images/pp-ss3.png"
import PSS1 from "../images/p-ss1.png"
import PSS2 from "../images/p-ss2.png"
import SEMA1 from "../images/sema-ss1.png"
import SEMA2 from "../images/sema-ss2.png"
import MEET1 from "../images/meet-ss1.png"
import PPCaseStudy from "../pdfs/PopcornPal Case Study.pdf"
import AMP1 from "../images/amp-ss1.png"
import AMP2 from "../images/amp-ss2.png"
import AMP3 from "../images/amp-ss3.png"

const Projects = () => {
  const [openSection, setOpenSection] = useState(null);

  const toggleSection = (index) => {
    setOpenSection(openSection === index ? null : index);
  };

  const handleLinkClick = (event) => {
    event.stopPropagation(); 
  }

  const handleCaseStudyClick = (event) => {
    window.open(PPCaseStudy, '_blank');
    event.stopPropagation(); 
  }

  return (
    <>
    <h5 style={{marginBottom: 5}}className="uk-text-bold">
        Projects
    </h5>

    <ul uk-accordion="collapsible: true;">

      <li className={`cursor ${openSection === 0 ? "uk-open" : ""}`} onClick={() => toggleSection(0)}>
        <a className='uk-accordion-title uk-text-default' href="#">PopcornPal</a>
        <div className='custom-flex'>
        <a target="_blank" aria-label="Visit PopcornPal live site" rel="noreferrer" onClick={handleLinkClick} href='https://popcornpal.netlify.app/'>Live site</a>
        <a target="_blank" aria-label="View PopcornPal case study" rel="noreferrer" onClick={handleLinkClick} href='https://github.com/monicaalyssa/popcornpal-client'><span uk-icon="icon: github-alt; ratio: 1.15"></span></a>
        </div>
        <p className='custom-badge'>React</p>
        <p className='custom-badge'>Node.js</p>
        <p className='custom-badge'>Express</p>
        <p className='custom-badge'>MongoDB</p>
      </li>

      {openSection === 0 && <div className='uk-animation-slide-top-small uk-accordion-content'><p className='uk-text-meta'>PopcornPal is a full-stack web application built in React, Node.js, Express, and MongoDB. It features a user-friendly platform that showcases detailed information about films and allows users to create accounts and favorite films.
        <p style={{marginTop: "0.3rem"}}><a target="_blank" rel="noreferrer" href={PPCaseStudy}>View Case Study <span uk-icon="icon: link-external; ratio: 0.75"></span></a></p>
      </p>
      
      <div uk-slideshow="animation: push">
      <div className="uk-position-relative uk-visible-toggle uk-light" tabIndex="-1">
        <div className="uk-slideshow-items">
          <div>
            <img src={PPSS1} loading="lazy" alt="Screenshot of PopcornPal's homepage" uk-cover />
          </div>
          <div>
            <img src={PPSS2} loading="lazy" alt="Screenshot of list of available movies to browse on PopcornPal" uk-cover />
          </div>
          <div>
            <img src={PPSS3} loading="lazy" alt="Screenshot of PopcornPal Scream movie details page" uk-cover />
          </div>
       </div>
        <a className="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slideshow-item="previous"></a>
        <a className="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slideshow-item="next"></a>
      </div>
      <ul className="uk-slideshow-nav uk-dotnav uk-flex-center uk-margin"></ul>
    </div>
    </div>
    }

    <div className='line'></div>

      <li className={`cursor ${openSection === 1 ? "uk-open" : ""}`} onClick={() => toggleSection(1)}>
        <a className='uk-accordion-title uk-text-default' href="#">Pokédex</a>
        <div className='custom-flex'>
        <a target="_blank" rel="noreferrer" aria-label="Visit Pokédex live site" onClick={handleLinkClick} href='https://monicaalyssa.github.io/pokedex/'>Live site</a>
        <a target="_blank" rel="noreferrer" aria-label="Visit Pokédex GitHub repository" onClick={handleLinkClick} href='https://github.com/monicaalyssa/pokedex'><span uk-icon="icon: github-alt; ratio: 1.15"></span></a>
        </div>   
        <p className='custom-badge'>JavaScript</p>
        <p className='custom-badge'>RESTful API</p>
        <p className='custom-badge'>Bootstrap</p>   
      </li>

      {openSection === 1 && <div className='uk-animation-slide-top-small uk-accordion-content'><p className='uk-text-meta'>Pokédex is a JavaScript web application that fetches 
        data from a Pokémon API to display information about various Pokémon species. It features a list of Pokémon with images and allows users to explore their details in an 
        interactive modal.</p>
      <div uk-slideshow="animation: push">
      <div className="uk-position-relative uk-visible-toggle uk-light" tabIndex="-1">
        <div className="uk-slideshow-items">
          <div>
            <img src={PSS1} loading="lazy" alt="Screenshot of Pokédex homepage" uk-cover />
          </div>
          <div>
            <img src={PSS2} loading="lazy" alt="Screenshot of open Pokémon modal for Charizard" uk-cover />
          </div>
       </div>
        <a className="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slideshow-item="previous"></a>
        <a className="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slideshow-item="next"></a>
      </div>
      <ul className="uk-slideshow-nav uk-dotnav uk-flex-center uk-margin"></ul>
    </div>
    </div>
    }

<div className='line'></div>


<li className={`cursor ${openSection === 4 ? "uk-open" : ""}`} onClick={() => toggleSection(4)}>
        <a className='uk-accordion-title uk-text-default' href="#">Mortgage Lender Website</a>
        <div className='custom-flex'>
        </div>   
        <p className='custom-badge no-live-link'>React</p>
        <p className='custom-badge no-live-link'>Mantine</p>  
        <p className='custom-badge no-live-link'>Vite</p>
      </li>

      {openSection === 4 && <div className='uk-animation-slide-top-small uk-accordion-content'><p className='uk-text-meta'>A local mortgage lender website built in React. This website offers various loan options and attracts potential borrower leads through interactive tools and resources. It also markets to realtors and loan originators helping them connect with the company and grow their businesses.
      </p>
      
      <div uk-slideshow="animation: push">
      <div className="uk-position-relative uk-visible-toggle uk-light" tabIndex="-1">
        <div className="uk-slideshow-items">
          <div>
            <img src={AMP1} loading="lazy" alt="Screenshot of landing page for mortgage company site" uk-cover />
          </div>
          <div>
            <img src={AMP2} loading="lazy" alt="Screenshot of mortgage options on mortgage company site" uk-cover />
          </div>
          <div>
            <img src={AMP3} loading="lazy" alt="Screenshot of a blog on mortgage company site" uk-cover />
          </div>
       </div>
        <a className="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slideshow-item="previous"></a>
        <a className="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slideshow-item="next"></a>
      </div>
      <ul className="uk-slideshow-nav uk-dotnav uk-flex-center uk-margin"></ul>
    </div>
    </div>
    }
    
    <div className='line'></div>

      <li className={`cursor ${openSection === 2 ? "uk-open" : ""}`} onClick={() => toggleSection(2)}>
        <a className='uk-accordion-title uk-text-default' href="#">Meet</a>
        <div className='custom-flex'>
        <a target="_blank" rel="noreferrer" aria-label="Visit Meet App live site" onClick={handleLinkClick} href='https://monicaalyssa.github.io/meet/'>Live site</a>
        <a target="_blank" rel="noreferrer" aria-label="Visit Meet App GitHub repository" onClick={handleLinkClick} href='https://github.com/monicaalyssa/meet'><span uk-icon="icon: github-alt; ratio: 1.15"></span></a>
        </div>   
        <p className='custom-badge'>React</p>
        <p className='custom-badge'>AWS</p>  
        <p className='custom-badge'>OAuth</p>
      </li>

      {openSection === 2 && <div className='uk-animation-slide-top-small uk-accordion-content'><p className='uk-text-meta'>Meet is a Progressive Web App (PWA) with offline functionality that allows users to search for upcoming events near their city. It includes interactive charts to visualize key event data.</p>
      <div uk-slideshow="animation: push">
      <div className="uk-position-relative uk-visible-toggle uk-light" tabIndex="-1">
        <div className="uk-slideshow-items">
          <div>
            <img src={MEET1} loading="lazy" alt="Screenshot of Meet application homepage" uk-cover />
          </div>
       </div>
        <a className="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slideshow-item="previous"></a>
        <a className="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slideshow-item="next"></a>
      </div>
      <ul className="uk-slideshow-nav uk-dotnav uk-flex-center uk-margin"></ul>
    </div>
    </div>
    }

    <div className='line'></div>

      <li className={`cursor ${openSection === 3 ? "uk-open" : ""}`} onClick={() => toggleSection(3)}>
        <a className='uk-accordion-title uk-text-default' href="#">SEMA (Hackathon)</a>
        <div className='custom-flex'>
        <a target="_blank" rel="noreferrer" aria-label="Visit SEMA (Hackathon Project) Devpost Submission"onClick={handleLinkClick} href='https://devpost.com/software/sema'>Devpost</a>
        <a target="_blank" rel="noreferrer" aria-label="Visit SEMA GitHub repository" onClick={handleLinkClick} href='https://github.com/sebilune/plutohacks-2024'><span uk-icon="icon: github-alt; ratio: 1.15"></span></a>
        </div>   
        <p className='custom-badge'>React</p>
        <p className='custom-badge'>SCSS</p>  
        <p className='custom-badge'>Vite</p>
        <p className='custom-badge'>RESTful API</p>   
        <p className='custom-badge'>GraphQL</p>   
      </li>

      {openSection === 3 && <div className='uk-animation-slide-top-small uk-accordion-content'><p className='uk-text-meta'>SEMA is an all-in-one emergency preparedness platform created for the 2024 PlutoHacks hackathon. It integrates multiple APIs to provide real-time updates on hazardous weather conditions and nearby resources. An AI chatbot is available to answer disaster-related questions.  This project was developed in collaboration with three team members.
      <p></p></p>
      
      <div uk-slideshow="animation: push">
      <div className="uk-position-relative uk-visible-toggle uk-light" tabIndex="-1">
        <div className="uk-slideshow-items">
          <div>
            <img src={SEMA1} loading="lazy" alt="Screenshot of SEMA application homepage" uk-cover />
          </div>
          <div>
            <img src={SEMA2} loading="lazy" alt="Screenshot of SEMA local weather warnings" uk-cover />
          </div>
       </div>
        <a className="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slideshow-item="previous"></a>
        <a className="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slideshow-item="next"></a>
      </div>
      <ul className="uk-slideshow-nav uk-dotnav uk-flex-center uk-margin"></ul>
    </div>
    </div>
    }
    </ul>
    </>
  );
};

export default Projects;
