import GitHub from "../images/github.svg";
import LinkedIn from "../images/linkedin.svg"

const SocialProfiles = () => {

    return (
        <div style={{marginTop: "3rem", gap: "0.3rem"}} className="uk-flex uk-flex-row uk-flex-center uk-flex-middle">
        <a href="https://github.com/monicaalyssa" aria-label="Visit Monica Alyssa's GitHub profile" target="_blank" rel="noreferrer"><img className="social-media-logo" src={GitHub} alt="GitHub logo"></img></a>
        <a href="https://linkedin.com/in/monica-alyssa/" aria-label="Visit Monica Alyssa's LinkedIn profile" target="_blank" rel="noreferrer"><img className="social-media-logo" src={LinkedIn} alt="LinkedIn logo"></img></a>
        </div>
    )
}

export default SocialProfiles;