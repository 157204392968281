import logo from "../images/logo.gif"
import GitHub from "../images/github.svg";
import LinkedIn from "../images/linkedin.svg"
import Medium from "../images/medium.svg"
import Resume from "../pdfs/Monica Williams Resume Web Developer.pdf"

const Introduction = () => {

    const handleResumeClick = (event) => {
        window.open(Resume, '_blank');
        event.stopPropagation(); 
      }

    return (
        <div>
            <div className="my-name">
            <h4 className="uk-text-bold" style={{marginBottom: 5}}>
                Monica Williams
            </h4>
            <img src={logo} className="App-logo" alt="Pink spinning heart" />
            </div>
            <h5 style={{marginTop: 0}}>
                Web Developer
            </h5>
            <p className="uk-text-meta">
                Hello! I'm Monica, a full-stack web developer based 
                in <a href="https://maps.app.goo.gl/bVj7rcHZSk8sdB7d6" aria-label="View Fort Lauderdale, Florida on Google Maps" 
                target="_blank" rel="noreferrer">Fort Lauderdale, Florida.</a> My passion for coding
                began at a young age, starting as self-taught and followed by computer-related
                courses in high school.
            </p>
            <p className="uk-text-meta">
                I am currently studying a full-stack web development program, where I am expanding my skills
                in both front-end and back-end technologies, including JavaScript, React, Python, and more.
            </p>
            <div style={{marginBottom: "1rem", gap: "0.5rem"}} className="uk-flex uk-flex-row uk-flex-middle">
            <a href="https://github.com/monicaalyssa" aria-label="Visit Monica Alyssa's GitHub profile" target="_blank" rel="noreferrer"><img className="social-media-logo" src={GitHub} alt="GitHub logo"></img></a>
            <a href="https://linkedin.com/in/monica-alyssa/" aria-label="Visit Monica Alyssa's LinkedIn profile" target="_blank" rel="noreferrer"><img className="social-media-logo" src={LinkedIn} alt="LinkedIn logo"></img></a>
            <a href="https://medium.com/@monicaalyssa" aria-label="Visit Monica Alyssa's Medium Blog" target="_blank" rel="noreferrer"><img className="social-media-logo" src={Medium} alt="Medium logo"></img></a>
            <button onClick={handleResumeClick} aria-label="View Monica Williams' Resume" className='resume-btn'>Resume<div className="hoverEffect"><div></div></div></button>
            </div>
        </div>
    )
};

export default Introduction;