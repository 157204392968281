import './App.css';
import ContactMe from './components/ContactMe';
import Copyright from './components/Copyright';
import Introduction from './components/Introduction';
import Projects from './components/Projects';
import Skills from './components/Skills';
import SocialProfiles from './components/SocialProfiles';

function App() {
  return (
    <div className="App-header">
        <Introduction />  
        <ContactMe />
        <Skills />  
        <Projects />
        <SocialProfiles />
        <Copyright />
    </div>
  );
}

export default App;
