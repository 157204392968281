import { useState } from "react";

const ContactMe = () => {
const [copied, setCopied] = useState(false);

const email = 'monicaalyssa.tech@gmail.com'
const copyEmail = () => {
    navigator.clipboard.writeText(email)
    .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
    })
    .catch((err) => console.error('Error copying email: ', err));
};

    return (
       <div className="email-container">
        <p style={{marginBottom: 0}} className="uk-text-small">monicaalyssa.tech@gmail.com</p>
        <div className="button-box">
        <button onClick={copyEmail} aria-label="Copy email address to clipboard" className="copy">
            <i className={ copied ? 'fa-solid fa-check' : 'fa-regular fa-copy' }></i> 
        </button>
        <a style={{ transition: "color 0.3s"}} aria-label="Send an email to monicaalyssa.tech@gmail.com" href="mailto:monicaalyssa.tech@gmail.com" className="mailto">
            <i className="fas fa-paper-plane"></i>
        </a>
        </div>
       </div>
    )
}

export default ContactMe;